import React from "react";
import Footer from "./Footer";
import BackButton from "./BackButton";

const PrivacyPolicy = () => {
    return (
        <>
            <div className='d-flex justify-content-end me-0 mt-0 mb-2 bg-dark'>
                <BackButton link={"/"} />
            </div>
            <div className="text-white min-vh-100">
                <h1>プライバシーポリシー</h1>
                <ol>
                    <li className="fs-4">個人情報の利用目的</li>

                    <p>
                        当サイトでは、お問い合わせの際、メールアドレス等の個人情報を入力いただく場合がございます。
                        取得した個人情報は、お問い合わせに対する回答や必要な情報を電子メールなどをでご連絡する場合に利用させていただくものであり、これらの目的以外では利用いたしません。
                    </p>

                    <li className="fs-4">広告について</li>

                    <p>
                        当サイトでは、第三者配信の広告サービス（GoogleAdSense）を利用しており、ユーザーの興味に応じた商品やサービスの広告を表示するため、クッキー（Cookie）を使用しております。
                        クッキーを使用することで当サイトはお客様のコンピュータを識別できるようになりますが、お客様個人を特定できるものではありません。
                        Cookieはブラウザから無効にすることができます。また、Amazonのアソシエイトとして、EFT Item Hubは適格販売により収入を得ています。
                    </p>
                    <li className="fs-4">アクセス解析ツールについて</li>

                    <p>
                        当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス、Googleサーチコンソール」を利用しています。これらはトラフィックデータの収集のためにクッキー（Cookie）を使用しております。トラフィックデータは匿名で収集されており、個人を特定するものではありません。
                    </p>
                    <li className="fs-4">免責事項</li>
                    <p>
                        当サイトからのリンクやバナーなどで移動したサイトで提供される情報、サービス等について一切の責任を負いません。
                        また当ブログのコンテンツ・情報について、できる限り正確な情報を提供するように努めておりますが、正確性や安全性を保証するものではありません。情報が古くなっていることもございます。
                        当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
                    </p>
                    <li className="fs-4">著作権について</li>
                    <p>
                        当サイトで掲載している文章や画像などにつきましては、無断転載することを禁止します。
                        当サイトは著作権や肖像権の侵害を目的としたものではありません。著作権や肖像権に関して問題がございましたら、お問い合わせフォームよりご連絡ください。迅速に対応いたします。
                    </p>
                    <li className="fs-4">リンクについて</li>
                    <p>
                        当サイトは基本的にリンクフリーです。リンクを行う場合の許可や連絡は不要です。
                        ただし、インラインフレームの使用や画像の直リンクはご遠慮ください。
                    </p>
                    <li className="fs-4">プライバシーポリシーの変更について</li>
                    <p>
                        当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。
                        修正された最新のプライバシーポリシーは常に本ページにて開示されます。
                    </p>
                </ol>
                <p>最終更新：2023/04/24</p>

            </div>
            <Footer />
        </>
    )
}

export default PrivacyPolicy;